/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, navigate, useStaticQuery } from "gatsby"
import Cookies from "js-cookie"
import React, { useCallback, useState } from "react"
import Modal from "react-modal"
import slugify from "slugify"
import { ICtaAction } from "../../@types/types"
import Footer from "./Footer"
import Header from "./Header"
import MailchimpSubscribeForm from "./MailchimpSubscribeForm"

const disableGACookieName = `ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`

const Layout = ({
  children,
  headerLinks,
  headerClassName,
}: {
  children: ({
    handleAction,
  }: {
    handleAction: (action: ICtaAction) => void
  }) => JSX.Element
  headerLinks?: { id: string; title: string; href: string }[]
  headerClassName?: string
}): JSX.Element => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      contentfulCookieBanner {
        title
        body {
          childMarkdownRemark {
            html
          }
        }
        acceptButtonText
        declineButtonText
        declineButtonLink {
          title
        }
      }
    }
  `)

  const [showSignup, setShowSignup] = useState(false)

  const handleAction = useCallback((action: ICtaAction) => {
    switch (action?.event) {
      case "SIGN_UP": {
        setShowSignup(true)
        return
      }
      case "SET_COOKIES": {
        if (action?.arguments?.includes("true")) onAccept()
        if (action?.arguments?.includes("false")) onDecline()
        return
      }
      default: {
        return
      }
    }
  }, [])

  const onAccept = () => {
    Cookies.set(disableGACookieName, "false", { expires: 365 })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[disableGACookieName] = false
    setShowCookieBanner(false)
  }

  const onDecline = () => {
    if (declineButtonLink?.title)
      return navigate(`/${slugify(declineButtonLink.title, { lower: true })}`)
    Cookies.set(disableGACookieName, "true", { expires: 365 })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[disableGACookieName] = true
    setShowCookieBanner(false)
  }

  const [showCookieBanner, setShowCookieBanner] = useState(
    !Cookies.get(disableGACookieName)
  )

  const {
    contentfulCookieBanner: {
      title,
      body: {
        childMarkdownRemark: { html },
      },
      acceptButtonText,
      declineButtonText,
      declineButtonLink,
    },
  } = data

  return (
    <>
      {showCookieBanner && (
        <aside className="bg-secondary-700 text-white py-4">
          <div className="container mx-auto grid grid-flow-row gap-4 xl:grid-cols-5 xl:gap-8">
            <p className="font-bold text-xl">{title}</p>
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              className="xl:col-span-3 text-sm"
            />
            <nav className="flex flex-wrap space-x-4 xl:flex-col xl:space-x-0">
              <button
                className="text-left xl:text-lg font-bold text-primary-500 hover:text-primary-700"
                onClick={onAccept}
              >
                {acceptButtonText}
              </button>
              <button
                className="text-left xl:text-lg font-bold text-primary-500 hover:text-primary-700"
                onClick={onDecline}
              >
                {declineButtonText}
              </button>
            </nav>
          </div>
        </aside>
      )}
      <div className="site">
        <Header links={headerLinks} className={headerClassName} />
        <main className="site__main">{children({ handleAction })}</main>
        <Footer />
        <Modal
          isOpen={showSignup}
          onRequestClose={() => setShowSignup(false)}
          overlayClassName="fixed z-20 inset-0 overflow-y-auto flex items-center justify-center min-h-screen min-w-screen bg-black bg-opacity-75"
          className="bg-white rounded-lg text-left overflow-hidden shadow-xl outline-none"
          contentElement={(props, children) => (
            <div className="container mx-auto my-8 max-w-screen-lg">
              <div {...props}>{children}</div>
            </div>
          )}
        >
          <MailchimpSubscribeForm onClose={() => setShowSignup(false)} />
        </Modal>
      </div>
    </>
  )
}

export default Layout
