import classNames from "classnames"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import slugify from "slugify"

const Footer = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulFooterNavigation {
        id
        links {
          id
          title
        }
      }
    }
  `)

  const {
    contentfulFooterNavigation: { links },
  } = data

  return (
    <footer className="bg-gray-100 border-t border-primary-500 py-4 text-gray-500 text-sm">
      <div className="container mx-auto grid gap-4 lg:grid-cols-2">
        <nav className="flex space-x-2 divide-x divide-secondary-300">
          {links &&
            links.map(
              ({ id, title }: { id: string; title: string }, index: number) => (
                <Link
                  key={id}
                  to={`/${slugify(title, { lower: true })}`}
                  className={classNames(
                    "font-medium text-primary-500 hover:text-primary-700 focus:text-primary-700 transition-colors",
                    {
                      "pl-2": index !== 0,
                    }
                  )}
                >
                  {title}
                </Link>
              )
            )}
        </nav>
        <p className="lg:text-right">
          &copy; Copyright {new Date().getFullYear()} Esparter. All rights
          reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
