import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useForm } from "react-hook-form"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const MailchimpSubscribeForm = ({
  onClose,
}: {
  onClose: () => void
}): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteMetaQuery {
      site {
        siteMetadata {
          mailchimpUrl
          email
          phone
          title
        }
      }
    }
  `)

  const { register, handleSubmit } = useForm()

  return (
    <MailchimpSubscribe
      url={data.site.siteMetadata.mailchimpUrl}
      render={({ subscribe, status, message }) => (
        <section>
          <header className="px-8 pt-8">
            <h1 className="text-3xl font-bold tracking-tight sm:text-4xl">
              Find out more
            </h1>
            <p className="mt-4 text-lg text-gray-500 sm:text-xl max-w-prose">
              Fill in the form below and include as many details as you can
              about your event or venue requirements. Alternatively, you can
              email us with your query at&nbsp;
              <a
                className="font-semibold text-primary-500 hover:text-primary-700"
                href={`mailto:${data.site.siteMetadata.email}`}
              >
                {data.site.siteMetadata.email}
              </a>
              . We will never pester you or share your contact details with
              third-parties.&nbsp;
              <a
                className="font-semibold text-primary-500 hover:text-primary-700"
                href="/privacy-policy"
              >
                See our privacy policy.
              </a>
            </p>
            {status === "error" && message && (
              <div
                className="mt-8 p-4 bg-red-100 text-red-500 rounded mailchimp-response"
                dangerouslySetInnerHTML={{
                  __html: typeof message === "string" ? message : "",
                }}
              />
            )}
          </header>
          {status === "success" && (
            <>
              <div className="p-8">
                <p className="p-4 bg-green-100 text-green-500 rounded">
                  Thank you! Someone will be in touch shortly to discuss.
                </p>
              </div>
              <div className="px-8 py-4 bg-gray-50 text-right">
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Close
                </button>
              </div>
            </>
          )}
          {status !== "success" && (
            <form onSubmit={handleSubmit(subscribe)}>
              <div className="p-8">
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                  <div className="col-span-2">
                    <label
                      htmlFor="MMERGE6"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name of your club or organisation*
                    </label>
                    <input
                      {...register("MMERGE6", { required: true })}
                      id="MMERGE6"
                      type="text"
                      placeholder="Club Name"
                      className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="FNAME"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First name*
                    </label>
                    <input
                      {...register("FNAME", { required: true })}
                      id="FNAME"
                      type="text"
                      placeholder="First Name"
                      className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="LNAME"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last name*
                    </label>
                    <input
                      {...register("LNAME", { required: true })}
                      id="LNAME"
                      type="text"
                      placeholder="Last Name"
                      className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="EMAIL"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email Address*
                    </label>
                    <input
                      {...register("EMAIL", { required: true })}
                      id="EMAIL"
                      type="email"
                      placeholder="Email Address"
                      className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="PHONE"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Telephone*
                    </label>
                    <input
                      {...register("PHONE", { required: true })}
                      id="PHONE"
                      type="text"
                      placeholder="Phone Number"
                      className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-2">
                    <label
                      htmlFor="MESSAGE"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Message
                    </label>
                    <textarea
                      {...register("MESSAGE")}
                      id="MESSAGE"
                      placeholder="Message"
                      className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
              <div className="px-8 py-4 bg-gray-50 text-right">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  {status === "sending" && (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span className="sr-only">Submitting</span>
                    </>
                  )}
                  Submit
                </button>
              </div>
            </form>
          )}
        </section>
      )}
    />
  )
}

export default MailchimpSubscribeForm
