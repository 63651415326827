import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import logo from "../images/orderwhizz.svg"

const Header = ({
  links,
  className = "",
}: {
  links?: { id: string; title: string; href: string }[]
  className?: string
}): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <header className={`py-6 ${className}`}>
      <nav
        className="container mx-auto flex flex-col items-center justify-between sm:flex-row lg:space-x-4 lg:justify-start"
        aria-label="Global"
      >
        <Link to="/" className="mb-6 sm:mb-0 block h-12 w-auto">
          <span className="sr-only">{data.site.siteMetadata.title}</span>
          <img
            src={logo}
            alt={`${data.site.siteMetadata.title} logo`}
            height={48}
            width={257}
          />
        </Link>
        <div className="flex items-center space-x-4 lg:space-x-0">
          {links &&
            links.map(({ id, title, href }) => (
              <Link
                key={id}
                to={href}
                className="font-semibold text-gray-500 hover:text-primary-500 bg-white transition-colors lg:px-4 lg:py-3 "
              >
                {title}
              </Link>
            ))}
        </div>
      </nav>
    </header>
  )
}

export default Header
